import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'

import Button from '../../components/Button/Button'
import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import Alert from '../../components/Alert/Alert'

const BuilderNewChecklistPage = () => {
  const intl = useIntl()
  return (
    <Layout
      view='builder'
      subheader={<FormattedMessage id='nav.builderDashB' />}
      header={
        <span className='bold'>
          <FormattedMessage id='newAppChecklist.pageHeader' />
        </span>
      }
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'newAppChecklist.pageHeader' })}
      />
      <Container>
        <Row className=''>
          <Col>
            <ol className='largeNums'>
              <li>
                <div>
                  <FormattedMessage id='newAppChecklist.list01a' />{' '}
                  <Link to='/builder-vendor/become-a-licensed-builder/do-i-need-a-licence/'>
                    <FormattedMessage id='newAppChecklist.list01b' />
                  </Link>{' '}
                  <FormattedMessage id='newAppChecklist.list01c' />
                </div>
              </li>
              <li>
                <div>
                  <FormattedMessage id='newAppChecklist.list02a' />{' '}
                  {/* <a
                    href='https://builderportal.hcraontario.ca/'
                    aria-label={intl.formatMessage({
                      id: 'newAppChecklist.list02b'
                    })}
                  > */}
                  <FormattedMessage id='newAppChecklist.list02b' />
                  {/* </a> */}
                  .
                  <br />
                  <br />
                  <Button
                    linkExternal
                    linkPath='https://builderportal.hcraontario.ca'
                    text={intl.formatMessage({
                      id: 'becomeBuilder5.contentCiii'
                    })}
                  />
                </div>
              </li>
              <li>
                <div>
                  <FormattedMessage id='newAppChecklist.list03a' />
                  <br />
                  <br />
                  <h2 className='sm'>
                    <FormattedMessage id='newAppChecklist.subHdrA' />
                  </h2>
                  <ul>
                    <li>
                      <FormattedMessage id='newAppChecklist.list04a' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list04b' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list04c' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list04d' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list04e' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list04f' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list04g' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list04h' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list04i' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list04j' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list04k' />
                    </li>
                  </ul>

                  <h2>
                    <FormattedMessage id='newAppChecklist.subHdrB' />
                  </h2>
                  <ul>
                    <li>
                      <FormattedMessage id='newAppChecklist.list05a' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list05b' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list05c' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list05d' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list05e' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list05f' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list05g' />
                    </li>
                  </ul>

                  <h2>
                    <FormattedMessage id='newAppChecklist.subHdrC' />
                  </h2>
                  <ul>
                    <li>
                      <FormattedMessage id='newAppChecklist.list06a' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list06b' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list06c' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list06d' />
                    </li>
                    <li>
                      <FormattedMessage id='newAppChecklist.list06e' />
                    </li>
                  </ul>
                </div>
              </li>
              <li className='centered'>
                <FormattedMessage id='newAppChecklist.list07' />
              </li>
              <li className='centered'>
                <FormattedMessage id='newAppChecklist.list08' />
              </li>
            </ol>

            <Alert>
              <FormattedMessage id='newAppChecklist.contentA' />{' '}
              <a
                href={`../../../${intl.formatMessage({
                  id: 'linkNames.newInstructionLink'
                })}`}
                aria-label={intl.formatMessage({
                  id: 'newAppChecklist.contentB'
                })}
              >
                <FormattedMessage id='newAppChecklist.contentB' />
              </a>{' '}
              <FormattedMessage id='newAppChecklist.contentC' />
            </Alert>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BuilderNewChecklistPage
